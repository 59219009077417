import React from 'react';
import './CV.css';

function ExhibitionsAndNews() {
  return (
    <div className="cv-container">
      <h1>Exhibitions & News</h1>
      
      <section>
        <h2>Upcoming Exhibitions</h2>
        <ul>
          <li>
            <strong>➀ Art in the Barn – Petoskey Spring</strong><br />
            Petoskey, Michigan<br />
            <time dateTime="2025-05-10">May 10-11, 2025</time><br />
            5/10/25 - 5/11/25
          </li>
          <li>
            <strong>➁ Traverse City Spring Art & Craft Show</strong><br />
            Traverse City, Michigan<br />
            <time dateTime="2025-05-31">May 31 - June 1, 2025</time><br />
            5/31/25 - 6/1/25
          </li>
          <li>
            <strong>➂ Grand Traverse Bay Summer Art & Craft Show</strong><br />
            Traverse City, Michigan<br />
            <time dateTime="2025-08-16">August 16-17, 2025</time><br />
            8/16/25 - 8/17/25
          </li>
          <li>
            <strong>➃ Art in the Barn 2025</strong><br />
            Barrington, Illinois<br />
            <time dateTime="2025-09-27">September 27-28, 2025</time><br />
            9/27/25 - 9/28/25
          </li>
        </ul>
      </section>
    </div>
  );
}

export default ExhibitionsAndNews;